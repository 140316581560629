import React from 'react';
import styled from 'styled-components';

import Icon from '../../styles/atoms/icons';

import AuthorItem from './AuthorItem';
import { atMinWidth } from '../../styles/atoms/breakpoints';

const AuthorBar = ({ read, author, publishDate }) => (
  <Container className="author-bar">
    <AuthorItem text={read} icon={<Icon id="FaRegClock" />} />
    <AuthorItem text={author?.firstName ? `${author.firstName} ${author.lastName}` : ''} icon={<Icon id="FaRegUser" />} />
    <AuthorItem
      text={`Published on ${publishDate}`}
      icon={<Icon id="FiCalendar" />}
    />
  </Container>
);

export default AuthorBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  ${atMinWidth.md`
    flex-direction: row;
    align-items: center;
    gap: 32px;
  `}
`;
